import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  SimpleChanges,
} from "@angular/core";
import { FunctionalityService } from "mn-common";
import { SideNavigatorService } from "../../services/side-navigator.service";
import { Observable, Subscription } from "rxjs";
import { ScreenSizeService } from "src/app/services/screen-size.service";
import { FeatureService } from "src/app/services/features.service";
import { Router } from "@angular/router";
import { distance } from "fastest-levenshtein";
import { AuthService } from "src/app/services/auth.service";
import { CacheService } from "src/app/services/cache.service";
import { NetworkService } from "src/app/services/network.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit, OnDestroy {
  @Input() docked: boolean = false; // Accept docked input parameter

  networkSubscription: Subscription | null = null;

  userInfo$: Observable<any>;
  org$: Observable<any>;

  features = [];
  currentFeature: any;
  isMobile: boolean;
  isDesktop: boolean;

  constructor(
    public auth: AuthService,
    public sideNavigatorService: SideNavigatorService,
    public functionalityService: FunctionalityService,
    public screenSizeService: ScreenSizeService,
    public featureService: FeatureService,
    private router: Router,
    private cacheService: CacheService,
    private networkService: NetworkService
  ) {}

  ngOnInit(): void {
    this.fetchUserData();

    this.networkSubscription = this.networkService
      .getOnlineStatus()
      .subscribe((isOnline) => {
        console.log("Network status changed:", isOnline);
        this.setFeaturesDescriptions();
      });

    this.screenSizeService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });

    this.screenSizeService.isDesktop$.subscribe((isDesktop) => {
      this.isDesktop = isDesktop;
    });
  }

  // ngOnChanges(changes: SimpleChanges): void {
  //   if (changes["isVisible"]?.currentValue === true) {
  //     this.networkService.checkInternetConnection().then((isConnected) => {
  //       // putting this insde the checkInternetConnection to make sure we have a valid connection
  //       this.setFeaturesDescriptions();
  //     });
  //   }
  // }

  private setFeaturesDescriptions() {
    this.featureService.getFeatureDescriptions().then((data) => {
      this.features = data;
      const currentRoute = this.router.url;
      this.currentFeature = this.findBestMatchingFeature(currentRoute);
    });
  }

  fetchUserData(): void {
    this.userInfo$ = this.auth.getUserInfo();
    this.org$ = this.auth.getOrganization();
  }

  ngOnDestroy(): void {
    if (this.networkSubscription) {
      this.networkSubscription.unsubscribe();
    }
  }

  navigateToHome(): void {
    this.router.navigate(["/home"]);
    this.currentFeature = this.findBestMatchingFeature("/home");
    this.sideNavigatorService.toggleSideNavigator();
  }

  navigateToFeature(path: string, queryParam: string): void {
    this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
      this.router.navigate(["/react-test", path.replace(/\//g, "")], {
        queryParams: { navigationParam: queryParam },
      });
      this.currentFeature = this.findBestMatchingFeature(path);
      this.sideNavigatorService.toggleSideNavigator();
    });
  }

  getFeatureIconUrl(feature: any): string {
    const iconUrl = `${feature.baseUrl}${feature.homeIcon}`;

    if (this.networkService.isOnline()) {
      return iconUrl;
    } else {
      var img = this.cacheService.getCachedItem(iconUrl);

      if (img) {
        if (typeof img === "string" && img.startsWith("data:image")) {
          // If cachedIcon is already in Base64 format, return it directly
          return img;
        } else if (img instanceof Blob) {
          // If cachedIcon is a Blob, create an object URL for it
          return URL.createObjectURL(img);
        }
        // If it's another data type or format, return it as a string or process as needed
        return img;
      } else {
        // Fallback if no cached data is available
        return "";
      }
    }
  }

  onImageError() {
    this.networkService
      .checkInternetConnection()
      .then((isConnected) => {
        if (isConnected) {
          console.log("Internet is active");
        } else {
          this.networkService.markInternetDown();
        }
      })
      .catch((error) =>
        console.error("Unexpected error in connectivity check:", error)
      );
  }

  findBestMatchingFeature(currentRoute: string): any {
    let bestMatch = null;
    let bestDistance = Infinity;

    this.features.forEach((feature) => {
      const dist = distance(
        currentRoute.replace(/\/react-test(\/|$)/, "/"),
        feature.defaultPath.replace(/\/react-test(\/|$)/, "/")
      );

      if (dist < bestDistance) {
        bestDistance = dist;
        bestMatch = feature;
      }
    });

    const dist = distance(currentRoute, "/home");
    if (dist < bestDistance) {
      bestDistance = dist;
      bestMatch = {
        name: "Home",
        defaultPath: "/home",
      };
    }

    return bestMatch;
  }

  findBestMatchingSubFeature(options): any {
    let bestMatch = null;
    let bestDistance = Infinity;
    const currentRoute = this.router.url;

    options.forEach((option) => {
      const dist = distance(currentRoute, option.name);

      if (dist < bestDistance) {
        bestDistance = dist;
        bestMatch = option;
      }
    });

    return bestMatch;
  }

  endsWithRoute(route) {
    const currentRoute = this.router.url;
    return decodeURIComponent(currentRoute).endsWith(route);
  }

  filterFeaturesForScreenSize() {
    if (this.isDesktop) {
      // If we are on a desktop, return all features
      return this.features;
    }

    // If not on desktop, filter out features with desktopOnly = true
    return this.features.filter((feature) => !feature.desktopOnly);
  }
}
