import {
  HttpBackend,
  HttpClient,
  HttpHeaders,
  HttpResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, from, of } from "rxjs";
import { map, switchMap, tap, catchError } from "rxjs/operators";
import { NetworkService } from "./network.service";

interface CachedResource {
  data: any;
  contentType: string;
}

@Injectable({
  providedIn: "root",
})
export class CacheService {
  private readonly cacheName = "resource-cache";
  private directHttpClient: HttpClient;

  constructor(
    private http: HttpClient,
    private httpBackend: HttpBackend,
    private networkService: NetworkService
  ) {
    this.directHttpClient = new HttpClient(this.httpBackend);
  }

  appendCacheBustingUrl(url) {
    const cacheBustingUrl = `${url}${
      url.includes("?") ? "&" : "?"
    }_=${new Date().getTime()}`;
    return cacheBustingUrl;
  }

  getResource(url: string, cache = false): Observable<any> {
    const cachedData = localStorage.getItem(this.cacheKey(url));
    if (cachedData) {
      if (cache) {
        this.updateCacheInBackground(url);
      }
      const parsedData = JSON.parse(cachedData) as CachedResource;
      return of(this.processParsedData(parsedData));
    } else {
      return this.fetchAndCacheResource(url, cache);
    }
  }

  private fetchAndCacheResource(url: string, cache): Observable<any> {
    try {
      // TODO consider task to do a formal feature versioning
      const cacheBustingUrl = `${url}${
        url.includes("?") ? "&" : "?"
      }_=${new Date().getTime()}`;

      // console.log("Cache Busting UrL: " + cacheBustingUrl);

      const response = url.includes("s3.amazonaws")
        ? this.directHttpClient.get(cacheBustingUrl, {
            headers: new HttpHeaders({ "Cache-Control": "no-cache" }),
            responseType: "blob",
            observe: "response",
          })
        : this.http.get(cacheBustingUrl, {
            headers: new HttpHeaders({ "Cache-Control": "no-cache" }),
            responseType: "blob",
            observe: "response",
          });

      return response.pipe(
        switchMap((res: HttpResponse<Blob>) => this.handleResponse(res)),
        tap((parsedData) => {
          if (cache && parsedData) {
            localStorage.setItem(
              this.cacheKey(url),
              JSON.stringify(parsedData)
            );
          }
        }),
        map(this.processParsedData),
        catchError((error) => {
          console.error(`Failed to fetch resource from: ${url}`, error);
          return of(null);
        })
      );
    } catch (error) {
      if (this.networkService.isNetworkError(error)) {
        this.networkService.markInternetDown();
      }
      return of(null);
    }
  }

  private handleResponse(
    response: HttpResponse<Blob>
  ): Observable<CachedResource> {
    const contentType = response.headers.get("Content-Type") || "";

    if (contentType === "application/json") {
      // Handle JSON by parsing it
      return from(
        response.body?.text().then((text) => ({
          data: JSON.parse(text),
          contentType,
        })) as Promise<CachedResource>
      );
    } else if (
      contentType.startsWith("text/") ||
      contentType === "image/svg+xml"
    ) {
      // Handle text and SVG by converting the Blob to text
      return from(
        response.body?.text().then((text) => ({
          data: text,
          contentType,
        })) as Promise<CachedResource>
      );
    } else if (contentType === "image/png" || contentType === "image/jpeg") {
      // Handle binary images by converting the Blob to a Base64 data URL
      return from(
        this.convertBlobToBase64(response.body!).then((base64Data) => ({
          data: base64Data,
          contentType,
        }))
      );
    } else {
      // Handle other Blob types (e.g., PDFs, etc.)
      // console.log("Handling non-text/blob data");
      return from(
        response.body?.text().then((text) => ({
          data: text,
          contentType,
        })) as Promise<CachedResource>
      );
    }
  }

  private convertBlobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  private processParsedData(parsedData: CachedResource): any {
    return {
      data: parsedData.data,
      contentType: parsedData.contentType,
    };
  }

  private updateCacheInBackground(url: string): void {
    try {
      const response = url.includes("s3.amazonaws")
        ? this.directHttpClient.get(url, {
            headers: new HttpHeaders({ "Cache-Control": "no-cache" }),
            responseType: "blob",
            observe: "response",
          })
        : this.http
            .get(url, {
              headers: new HttpHeaders({ "Cache-Control": "no-cache" }),
              responseType: "blob",
              observe: "response",
            })
            .pipe(
              switchMap((response: HttpResponse<Blob>) =>
                this.handleResponse(response)
              ),
              tap((parsedData) => {
                if (parsedData) {
                  localStorage.setItem(
                    this.cacheKey(url),
                    JSON.stringify(parsedData)
                  );
                }
              }),
              catchError((error) => {
                console.error(
                  `Failed to update cached resource from: ${url}`,
                  error
                );
                return of(null);
              })
            )
            .subscribe();
    } catch (error) {
      if (this.networkService.isNetworkError(error)) {
        this.networkService.markInternetDown();
      }
    }
  }

  private cacheKey(url: string): string {
    return `${this.cacheName}-${url}`;
  }

  clearResource(url: string): void {
    localStorage.removeItem(this.cacheKey(url));
  }

  clearAllResources(): void {
    Object.keys(localStorage)
      .filter((key) => key.startsWith(this.cacheName))
      .forEach((key) => localStorage.removeItem(key));
  }

  isResourceCached(url: string): boolean {
    return localStorage.getItem(this.cacheKey(url)) !== null;
  }

  getAllResources(): any[] {
    return Object.keys(localStorage)
      .filter((key) => key.startsWith(this.cacheName))
      .map((key) => {
        const parsedData = JSON.parse(
          localStorage.getItem(key) || ""
        ) as CachedResource;
        return this.processParsedData(parsedData);
      });
  }

  getCachedItems(filterFn: (key: string) => boolean): any[] {
    return Object.keys(localStorage)
      .filter((key) => key.startsWith(this.cacheName) && filterFn(key))
      .map((key) => {
        const parsedData = JSON.parse(
          localStorage.getItem(key) || ""
        ) as CachedResource;
        return this.processParsedData(parsedData);
      });
  }

  getCachedItem(key: string): any {
    // Construct the full key name based on cacheName
    const fullKey = this.cacheKey(key);

    if (!localStorage.getItem(fullKey)) {
      return null;
    }

    // Retrieve the item from localStorage
    const cachedItem = JSON.parse(localStorage.getItem(fullKey));

    if (cachedItem) {
      const cachedIcon = cachedItem.data;
      const contentType = cachedItem.contentType; // Ensure contentType is stored with the data

      if (cachedIcon instanceof Blob) {
        // If cachedIcon is a Blob, create an object URL for it
        return URL.createObjectURL(cachedIcon);
      } else if (typeof cachedIcon === "string") {
        // Check if cached data already starts with "data:image" (meaning it's already Base64 encoded)
        if (cachedIcon.startsWith("data:image")) {
          return cachedIcon;
        }

        // Append the appropriate prefix for the content type
        const prefix = `data:${contentType};base64,`;
        return `${prefix}${this.toBase64(cachedIcon)}`;
      }
    }

    // Fallback if no data is available
    return "";
  }

  private toBase64(binaryString: string): string {
    const binaryData = new Uint8Array(
      [...binaryString].map((char) => char.charCodeAt(0))
    );
    let base64String = "";

    binaryData.forEach((byte) => {
      base64String += String.fromCharCode(byte);
    });

    return btoa(base64String);
  }
}
