<div id="footer">
  <div
    class="footer-icon-container"
    *ngFor="let fi of homeIconData.footerIcons"
    (click)="navigateToFeature(homeIconData.defaultPath, null)"
  >
    <div *ngIf="!isCurrentRouteHome()" class="footer-icon">
      <img [src]="fi.iconSrc" alt="Home" (error)="onImageError()" />
    </div>
    <div *ngIf="isCurrentRouteHome()" class="footer-icon-selected">
      <img [src]="fi.negIconSrc" alt="Home" (error)="onImageError()" />
    </div>
    <p class="footer-icon-label">{{ fi.name }}</p>
  </div>
  <ng-container *ngIf="featureBaseUrl">
    <div
      class="footer-icon-container"
      *ngFor="let fi of footerIcons"
      (click)="navigateToFeature(defaultPath, fi.path)"
    >
      <div *ngIf="!doesCurrentRouteMatch(fi.path)" class="footer-icon">
        <img [src]="getIconSrc(fi)" [alt]="fi.name" (error)="onImageError()" />
      </div>
      <div *ngIf="doesCurrentRouteMatch(fi.path)" class="footer-icon-selected">
        <img
          [src]="getNegIconSrc(fi)"
          [alt]="fi.name"
          (error)="onImageError()"
        />
      </div>
      <p class="footer-icon-label">{{ fi.name }}</p>
    </div>
  </ng-container>
</div>
