<!-- *ngIf="!(sideNavigatorService.hideSideNav$ | async) || docked" -->
<div
  id="sidebar"
  [class.expanded]="!(sideNavigatorService.hideSideNav$ | async)"
>
  <div id="close-button" *ngIf="!docked">
    <img
      src="../../../assets/redesign/close-icon.svg"
      alt="close icon"
      (click)="sideNavigatorService.toggleSideNavigator()"
    />
  </div>
  <div id="toptab" *ngIf="!isDesktop">
    <div class="spacer"></div>
    <div class="circle">
      <img
        src="../../../assets/redesign/person-menu-icon.svg"
        alt="icon"
        class="circle-icon"
      />
    </div>
  </div>
  <div id="desktop-toptab" *ngIf="isDesktop">
    <img src="/assets/redesign/login/login_mn_title.svg" />
  </div>
  <div id="sidebar-title">
    <span
      >{{ (userInfo$ | async)?.firstname }}
      {{ (userInfo$ | async)?.lastname }}</span
    >
    <span>{{ (org$ | async)?.name }}</span>
  </div>
  <div class="sidebar-separator"></div>
  <div id="sidebar-body">
    <div id="sidebar-content">
      <div
        [ngClass]="{ 'selected-link-content': currentFeature && '/home' ===
        currentFeature.defaultPath, 'link-content': !currentFeature || '/home'
        !== currentFeature.defaultPath, }"
        (click)="navigateToHome()"
        clickable
      >
        <img
          src="../../../assets/redesign/home-sidebar-icon.svg"
          alt="home icon"
        />
        <span>Home</span>
      </div>
      <div
        *ngFor="let feature of filterFeaturesForScreenSize()"
        class="link-container"
      >
        <ngContainer
          class="feature-link-content"
          *ngIf="
            currentFeature && feature.defaultPath === currentFeature.defaultPath
          "
        >
          <div
            [ngClass]="{
              'selected-link-content':
                currentFeature &&
                feature.defaultPath === currentFeature.defaultPath,
              'link-content':
                !currentFeature ||
                feature.defaultPath !== currentFeature.defaultPath,
            }"
            (click)="
              navigateToFeature(feature.defaultPath, feature.defaultRoute)
            "
            clickable
          >
            <img
              class="feature-icon"
              [src]="getFeatureIconUrl(feature)"
              alt="{{ feature.homeName }}"
              (error)="onImageError()"
            />
            <span>{{ feature.homeName }}</span>
          </div>
          <div
            class="sub-feature-parent-container"
            *ngIf="feature.footerIcons.length > 0"
          >
            <div
              *ngFor="let subFeature of feature.footerIcons; let last = last"
              class="sub-feature-container"
              (click)="navigateToFeature(feature.defaultPath, subFeature.path)"
              clickable
            >
              <div class="bullet-container">
                <div class="bullet"></div>
                <div *ngIf="!last" class="hash-line"></div>
              </div>
              <span
                [ngClass]="{
                'sub-menu-item': !endsWithRoute(subFeature.path),
                'selected-sub-menu-item': endsWithRoute(subFeature.path),
              }"
                >{{ subFeature.name }}</span
              >
            </div>
          </div>
        </ngContainer>
        <ngContainer
          class="feature-link-content"
          *ngIf="
            !currentFeature ||
            feature.defaultPath !== currentFeature.defaultPath
          "
        >
          <div
            [ngClass]="{
              'selected-link-content':
                currentFeature &&
                feature.defaultPath === currentFeature.defaultPath,
              'link-content':
                !currentFeature ||
                feature.defaultPath !== currentFeature.defaultPath,
            }"
            (click)="
              navigateToFeature(feature.defaultPath, feature.defaultRoute)
            "
            clickable
          >
            <img
              [src]="getFeatureIconUrl(feature)"
              alt="{{ feature.homeName }}"
              (error)="onImageError()"
            />
            <span>{{ feature.homeName }}</span>
          </div>
        </ngContainer>
      </div>
      <div
        class="link-content"
        (click)="sideNavigatorService.toggleSideNavigator(); auth.logout()"
        clickable
      >
        <img
          src="../../../assets/redesign/logout-sidebar-icon.svg"
          alt="logout icon"
        />
        <span>Logout</span>
      </div>
      <div class="content-buffer"></div>
    </div>
  </div>
  <div id="sidebar-footer"></div>
</div>
<!-- <nav
  id="sidebar"
  class="navbar-dark bg-dark"
  [ngClass]="{ hidden: sideNavigatorService.hideSideNav }"
> -->
<!-- <div
    class="paddingLT"
    *ngIf="
      functionalityService.includeAuthentication().toLowerCase() === 'true' &&
      (auth.isLoggedIn() | async)
    "
  >
    <span>Hello {{ userFirstName }}</span>
  </div>
  <div
    class="paddingLT"
    *ngIf="
      functionalityService.includeAuthentication().toLowerCase() === 'true' &&
      !(auth.isLoggedIn() | async)
    "
  >
    <span>You are not logged in</span>
  </div> -->
<!-- <ul class="navbar-nav">
    <li class="nav-item active">
      <a class="nav-link" [routerLink]="['/home']">Home</a>
    </li>
    <li class="nav-item active">
      <a class="nav-link" [routerLink]="['/about']">About Us</a>
    </li>
    <li class="nav-item active">
      <a class="nav-link" [routerLink]="['/support']">Contact Us</a>
    </li>
    <li class="nav-item active">
      <a class="nav-link" [routerLink]="['/disclaimer']">Disclaimer</a>
    </li>
    <li class="nav-item active">
      <a class="nav-link" [routerLink]="['/release-notes']">Release Notes</a>
    </li>
    <li class="nav-item active">
      <a class="nav-link" [routerLink]="['/version']">Version</a>
    </li>
    <li class="nav-item active">
      <a class="nav-link" [routerLink]="['/push-support']">Push Support</a>
    </li>
    <li class="nav-item active">
      <a class="nav-link" [routerLink]="['/react-test', 'dispatch']"
        >Dispatch</a
      >
    </li> -->
<!-- <li
      class="nav-item active"
      *ngIf="
        functionalityService.includeAuthentication().toLowerCase() === 'true' &&
        (auth.isLoggedIn() | async)
      "
    >
      <app-logout></app-logout>
    </li>
    <li
      class="nav-item active"
      *ngIf="
        functionalityService.includeAuthentication().toLowerCase() === 'true' &&
        !(auth.isLoggedIn() | async)
      "
    >
      <app-login></app-login>
    </li> -->
<!-- </ul>
</nav> -->
