export const environment = {
  appVersion: require("../../package.json").version,
  production: true,
  baseHref: "",
  timeStamp: "12/05/2024 10:38:11 PM EST",
  clientId: "ZnTmhRQa3NHj0wikHigzpBksYjOiSk0I",
  connection: "accc",
  organization: "accc",
  includeKingLtd: "true",
  includePediaccurate: "true",
  includeAuthentication: "true",
  apiUrl: "https://api-dev.medicnowsolutions.com",
  firebase: {
    appId: "1:612759992770:web:f875e2825054db4102be5b",
    apiKey: "AIzaSyDeQT11lZjAeycPzBW3rryIra3e_z15Yfw",
    authDomain: "mypushsupport.firebaseapp.com",
    databaseURL: "YOUR_DATABASE_URL",
    projectId: "mypushsupport",
    storageBucket: "mypushsupport.appspot.com",
    messagingSenderId: "612759992770",
  },
};
