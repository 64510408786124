<div id="header">
  <div class="non-title-container">
    <button
      id="header-menu-button"
      (click)="sideNavigatorService.toggleSideNavigator()"
    >
      <img src="assets/redesign/home/menu-icon.svg" alt="Menu" />
    </button>
  </div>
  <div id="title-container" class="fake-right-padding">
    <span class="header-title">{{ title }}</span>
    <!-- <span class="header-subtitle">{{ online }}</span> -->
  </div>
</div>
<!-- <div class="headerStyle flexCenter">
  <div class="leftDiv">
    <a
      href="javascript:void(0)"
      (click)="goBack()"
      [style.visibility]="isBackVisible() ? 'visible' : 'hidden'"
    >
      <img class="backImg" src="assets/icons/backward_icon.png" alt="back" />
    </a>
  </div>
  <img class="headerTitleImg" src="assets/name_icon.png" alt="Medic Now" />
  <div class="rightDiv">
    <img
      [ngClass]="sideNavigatorService.getToggleClass()"
      src="assets/menu_icon.png"
      id="menu"
      alt="menu"
      (click)="sideNavigatorService.toggleSideNavigator()"
      [style.visibility]="isMenuVisible() ? 'visible' : 'hidden'"
    />
  </div>
</div> -->
