<div id="app-home">
  <ng-container *ngIf="isDesktop"> </ng-container>
  <div id="app-home-inner-content">
    <div id="home-header" *ngIf="!isDesktop">
      <div class="non-title-container">
        <button
          id="menu-button"
          (click)="sideNavigatorService.toggleSideNavigator()"
        >
          <img src="assets/redesign/home/menu-icon.svg" alt="Menu" />
        </button>
      </div>
      <div id="home-title" class="large-title">Medic Now</div>
      <div id="home-logo">
        <img id="home-logo-img" [src]="organizationLogoUrl" alt="Logo" />
      </div>
    </div>
    <div id="desktop-home-header-container" *ngIf="isDesktop">
      <div id="desktop-home-header" *ngIf="isDesktop">
        <div id="header-mask"></div>
        <div id="header-name-and-org">
          <span>Welcome {{ firstName }}</span>
        </div>
        <div id="header-spacer"></div>
        <div id="header-badge">
          <img
            id="home-logo-img-desktop"
            [src]="organizationLogoUrl"
            alt="Logo"
          />
        </div>
      </div>
    </div>
    <div id="home-body">
      <!-- <div id="overlay-div" *ngIf="!isDesktop">
        <div id="home-greeting" class="small-title">
          Welcome <b>{{ firstName }}</b
          >,
        </div>
        <div id="home-title" class="large-title">Medic<br />Now</div>
        <div id="home-logo">
          <img id="home-logo-img" [src]="organizationLogoUrl" alt="Logo" />
        </div>
      </div> -->
      <div id="feature-scrollable-container">
        <div id="feature-container">
          <div
            *ngFor="let feature of filterFeaturesForScreenSize()"
            class="feature-tile"
            (click)="
              navigateToFeature(feature.defaultPath, feature.defaultRoute)
            "
            clickable
          >
            <div class="item-wrapper">
              <div class="item-icon-container">
                <img
                  [src]="getFeatureIcon(feature)"
                  [alt]="feature.homeName"
                  (error)="onImageError()"
                />
              </div>
              <p class="item-text">{{ feature.homeName }}</p>
            </div>

            <!-- <div class="feature-icon-container">
              <img
                [src]="getFeatureIcon(feature)"
                [alt]="feature.homeName"
                (error)="onImageError()"
              />
            </div>
            <div id="home-icon-label-container">
              <p class="home-icon-label">{{ feature.homeName }}</p>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div id="spacer"></div>
    <app-footer id="app-footer" *ngIf="!isDesktop"></app-footer>
  </div>
</div>
