import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FeatureService } from "../services/features.service";
import { ScreenSizeService } from "../services/screen-size.service";
import { CacheService } from "../services/cache.service";
import { map } from "rxjs";

@Component({
  selector: "app-react-native-bundle",
  templateUrl: "./react-native-bundle.component.html",
  styleUrls: ["./react-native-bundle.component.scss"],
})
export class ReactNativeBundleComponent implements OnInit, OnDestroy {
  isMobile: boolean;
  isDesktop: boolean;
  featureId: string;
  baseUrl: string;
  homeName: string;

  constructor(
    private route: ActivatedRoute,
    private featureService: FeatureService,
    private screenSizeService: ScreenSizeService,
    private cacheService: CacheService
  ) {
    this.screenSizeService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });

    this.screenSizeService.isDesktop$.subscribe((isDesktop) => {
      this.isDesktop = isDesktop;
    });
  }

  ngOnInit(): void {
    this.resetSafeAreaInsets();

    if (!this.isDesktop) {
      document.body.classList.add("react-native-header-background");
      document.body.classList.add("react-native-footer-background");
    }

    this.route.paramMap.subscribe((params) => {
      this.featureId = params.get("featureId");
      console.log(`feature id: ${this.featureId}`);
      this.featureService
        .getFeatureDescriptionByBasePath(this.featureId)
        .then((rslt) => {
          sessionStorage.setItem("feature-base-path", rslt.baseUrl);
          this.baseUrl = rslt.baseUrl;
          this.homeName = rslt.homeName;

          const navigationParam =
            this.route.snapshot.queryParamMap.get("navigationParam");

          (window as any).initialNavigation = navigationParam;

          this.loadReactNativeBundle(
            this.featureId,
            navigationParam,
            rslt.offline === "true"
          );
        });
    });
  }

  ngOnDestroy(): void {
    document.body.classList.remove("react-native-header-background");
    document.body.classList.remove("react-native-footer-background");
  }

  resetSafeAreaInsets() {
    const htmlElement = document.documentElement;
    const innerHeight = window.innerHeight;
    htmlElement.style.setProperty("--client-height", `${innerHeight}px`);
  }

  async loadReactNativeBundle(
    featureId: string,
    navigationParam: string,
    isOffline: boolean
  ): Promise<void> {
    console.log("navigation: " + navigationParam);

    const indexUrl = `${this.baseUrl}index.html`;
    const bundleUrl = `${this.baseUrl}bundle.js`;

    try {
      const indexResponse = await this.cacheService
        .getResource(indexUrl)
        .pipe(map((response) => response?.data))
        .toPromise();
      const html = indexResponse;

      document.getElementById("react-native-body").innerHTML = html;

      const bundleResponse = isOffline
        ? "data:text/javascript;base64," +
          btoa(
            unescape(
              encodeURIComponent(
                await this.cacheService
                  .getResource(bundleUrl)
                  .pipe(map((response) => response?.data))
                  .toPromise()
              )
            )
          )
        : this.cacheService.appendCacheBustingUrl(bundleUrl);

      console.log("this should have bundle cache breaking url:");
      console.log(bundleResponse);

      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = bundleResponse;
      script.onload = () => {
        const computedStyle = getComputedStyle(document.documentElement);
        const htmlElement = document.documentElement;
        const clientHeight = computedStyle.getPropertyValue("--client-height");
        console.log(`ClientHeight: ${clientHeight}`);
        htmlElement.style.height = clientHeight.trim();
      };

      document.body.appendChild(script);
    } catch (error) {
      console.warn("Error loading React app:", error);
    }
  }
}
